import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import React, { useState, useRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Hidden, Container, Grid, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { rgba } from 'polished';
import clsx from 'clsx';
import isNull from 'lodash/isNull';
import Slider from 'react-slick';
import StarIcon from '@material-ui/icons/Star';

import { styleConfig } from '../../config/config';

import SliderNavigation from './SliderNavigation';
import Title from './Common/Title';

const ContentModuleReviews: React.FunctionComponent<ContentModuleReviewsProps> = (props) => {
  const data = useStaticQuery(graphql`
    {
      allGoogleReview(limit: 5, filter: { rating: { eq: 5 } }) {
        edges {
          node {
            __typename
            id
            body
            rating
            source
            source_link
            position
            source_image
            date
          }
        }
      }
      allReviewsJson(limit: 5, filter: { rating: { eq: 5 } }) {
        edges {
          node {
            __typename
            id
            authorName
            body
            rating
            postedAt
          }
        }
      }
    }
  `);

  const slidesMerged = [...data.allGoogleReview.edges, ...data.allReviewsJson.edges];
  const slides = slidesMerged;

  const { title, preTitle, isDark } = props;

  const [slideIndex, setSlideIndex] = useState(0);
  const slidesLength = (slides && slides.length) || 0;
  const slidesToScroll = 1.5;

  const slider = useRef(null);

  const handleNext = () => {
    // @ts-ignore: Unknown Slick Slider ref type
    slider.current.slickGoTo(
      slideIndex < slidesLength - slidesToScroll ? slideIndex + slidesToScroll : slideIndex
    );
    setSlideIndex(
      slideIndex < slidesLength - slidesToScroll ? slideIndex + slidesToScroll : slideIndex
    );
  };

  const handlePrev = () => {
    // @ts-ignore: Unknown Slick Slider ref type
    slider.current.slickGoTo(slideIndex > 0 ? slideIndex - slidesToScroll : slideIndex);
    setSlideIndex(slideIndex > 0 ? slideIndex - slidesToScroll : slideIndex);
  };

  const settings = {
    dots: false,
    lazyLoad: 'ondemand',
    draggable: false,
    infinite: false,
    pagination: false,
    slidesToShow: 2.5,
    swipeToSlide: false,
    swipe: false,
    slidesToScroll: 1.25,
    responsive: [
      {
        breakpoint: 1201,
        settings: {
          swipeToSlide: true,
          swipe: true,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          swipeToSlide: true,
          swipe: true,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 479,
        settings: {
          swipeToSlide: true,
          swipe: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1,
        settings: {
          swipeToSlide: true,
          swipe: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const Rating: React.FunctionComponent<RatingProps> = (props) => {
    const { rating, id } = props;

    return (
      <>
        {[...Array.from({ length: rating })].map((star, index) => (
          <StarIcon htmlColor="#FFD700" key={`${id}-${index}`} />
        ))}
      </>
    );
  };

  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      padding: '100px 0',
      backgroundColor: props.isDark ? styleConfig.color.bg.darkgrey : styleConfig.color.bg.white,
    },
    slider: {
      maxWidth: '100%',
      overflowX: 'hidden',
      position: 'relative',
      '& .slick-slide': {
        padding: '0 40px 0 0',
        '&:focus': {
          outline: 'none',
        },
      },
      [theme.breakpoints.down('md')]: {
        '& .slick-slide': {
          padding: '0 0 0 0',
        },
      },
    },
    rating: {
      marginBottom: 20,
    },
    slide: {
      marginTop: 50,
      marginBottom: 50,
      marginLeft: 100,
      position: 'relative',
      outline: 0,

      [theme.breakpoints.down('md')]: {
        marginLeft: 0,
      },

      '&:before': {
        content: '"“"',
        color: styleConfig.color.text.gold,
        fontSize: 80,
        fontFamily: 'Bai Jamjuree',
        lineHeight: 0.7,
        position: 'absolute',
        left: -60,
        top: -30,
        fontWeight: 600,

        [theme.breakpoints.down('md')]: {
          top: '-50px',
          left: 0,
        },
      },
    },
    review: {
      color: styleConfig.color.text.gray,
      marginTop: 0,
      marginBottom: 25,
      minHeight: 120,
    },
    starDefault: {
      fontSize: 20,
      color: styleConfig.color.text.darkgrey,
      opacity: 0.2,
      marginRight: 5,
    },
    starSelected: {
      fontSize: 20,
      color: styleConfig.color.text.gold,
      opacity: 1,
    },
    name: {
      fontWeight: 600,
      marginBottom: 0,
      color: styleConfig.color.text.gold,
    },
    source: {
      color: props.isDark
        ? rgba(styleConfig.color.text.white, 0.4)
        : rgba(styleConfig.color.text.darkgrey, 0.4),
      fontSize: 14,
      margin: 0,
      '& > span': {
        textDecoration: 'underline',
      },
    },
  }));

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        {!isNull(title) && !isNull(preTitle) && title && (
          <Title
            titleColor={isDark ? styleConfig.color.bg.white : styleConfig.color.text.darkgrey}
            component="h2"
            isSubtitle
            marginBottom={25}
            subtitleText={preTitle}
            subtitleType="body1"
            titleHeavy
            titleSize="h2"
            titleText={title}
          />
        )}

        <Slider {...settings} ref={slider} className={clsx(['slider-reviews', classes.slider])}>
          {slides &&
            slides.map((slide, index) => {
              const sourceType = slide.node.__typename;
              const reviewsourceType = sourceType === 'GoogleReview' ? 'Google' : 'reviews.co.uk';

              return (
                <Grid container key={`cm-review-slider-${index}`} justifyContent="center">
                  <Grid item md={9} className={classes.slide}>
                    <Typography className={classes.review}>{slide.node.body}</Typography>

                    <Rating rating={slide.node.rating} id={slide.node.id} />

                    <p className={classes.source}>
                      source: <span>{reviewsourceType}</span>
                    </p>
                  </Grid>
                </Grid>
              );
            })}
        </Slider>

        {slidesLength >= 2 && (
          <Hidden mdDown>
            <Grid item lg={12}>
              <SliderNavigation
                handleNext={handleNext}
                handlePrev={handlePrev}
                slideIndex={slideIndex}
                slidesTotal={slidesLength}
                style="light"
              />
            </Grid>
          </Hidden>
        )}
      </Container>
    </div>
  );
};

interface RatingProps {
  id: string;
  rating: number;
}

interface ContentModuleReviewsProps {
  title: string;
  isDark?: boolean;
  preTitle?: string;
}

export default ContentModuleReviews;
